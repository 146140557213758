<template>
  <div class="display-contents">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'auth-layout'
}
</script>

<style lang="stylus" scoped>
.display-contents
  display contents
</style>
